export const API_URL = 'https://developionica.crizz.com.co'
export const MAP = 'https://maps.googleapis.com/maps/api/geocode/json'
export const KEY_MAP = 'AIzaSyCj628LOKWc_uxZcl9t-EZNzxuTg1xDMlk'

/**
 * 
Info de Usuario /user/:id
Booking /booking/user/:id
Profits /profits/user/:id
Suites del Partner en el admin /suite/user/:id

 */

export const ENDPOINTS = {
  LOGIN: '/user/admin/login',
  USER: '/user',
  ADMINS: '/user/admin',
  USER_INFO: '/user/info',
  SETTINGS: '/settings/general',
  FAQ: '/settings/faq',
  LANGUAGES: '/language',
  CITIES: '/settings/city',
  APARTMENTS: '/project',
  AMENITIES: '/amenities',
  SUITES: '/suite',
  SUITES_PROFITS: '/profits/suite',
  SUITES_STATISTICS: '/statistics',
  SUITE_CALENDAR: '/schedule/admin',
  SUITE_CALENDAR_DAY: '/schedule/day',
  SUITE_CALENDAR_MONTH :'/schedule/month',
  FINANCIAL_INCOME: '/financial/income',
  FINANCIAL_PENALTY: '/financial/penalty',
  BOOKINGS: '/booking/admin',
  BOOKINGS_STATUS: '/booking/status',
  PROFILEUSER: '/user',
  PROFILEUSER_BOOKINGS: '/booking/user',
  PROFILEUSER_PROFITS: '/profits/user',
  PROFILEUSER_SUITES: '/suite/user',
  DASHBOARD: '/dashboard'
}
